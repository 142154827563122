import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
    const location = useLocation();
    const handleRemoveClass = () => {
        document.body.classList.remove('sidenav-toggled')
    }

    return (
        <div id="layoutSidenav_nav">
            <nav className="sidenav shadow-right sidenav-light">
                <div className="sidenav-menu">
                    <div className="nav accordion" id="accordionSidenav">
                        <div className="sidenav-menu-heading">pages</div>
                        <Link to="/dashboard" className={`nav-link collapsed ${location?.pathname === "/dashboard" && "active"}`} onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fa fa-th"></i></div>
                            Dashboard
                        </Link>

                        {/* sub Admin */}
                        <div>
                            <div className={`nav-link collapsed ${['/sub_admin_list', '/add_sub_admin', '/edit_sub_admin'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseSubadmin" aria-expanded="false" aria-controls="collapseSubadmin">
                                <div className="nav-link-icon"><i className="fas fa-user-shield"></i></div>
                                Sub Admin
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/sub_admin_list', '/add_sub_admin', '/edit_sub_admin'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseSubadmin" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/sub_admin_list' ? 'active' : ''}`} to="/sub_admin_list"
                                        onClick={handleRemoveClass}
                                    >
                                        Subadmin List
                                    </Link>

                                    <Link className={`nav-link ${location?.pathname === '/add_sub_admin' ? 'active' : ''}`} to="/add_sub_admin"
                                        onClick={handleRemoveClass}>
                                        Add New Subadmin
                                    </Link>
                                </nav>
                            </div>
                        </div>


                        {/*  Traders */}
                        <div>
                            <div className={`nav-link collapsed ${['/trade_list', '/traders_details'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseTraders" aria-expanded="false" aria-controls="collapseTraders">
                                <div className="nav-link-icon"><i className="fas fa-users"></i></div>
                                Traders
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/trade_list', '/traders_details'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseTraders" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/trade_list' ? 'active' : ''}`} to="/trade_list"
                                        onClick={handleRemoveClass}>
                                        Traders List
                                    </Link>
                                </nav>
                            </div>
                        </div>

                        {/* Personal KYC Manager */}
                        <div>
                            <div className={`nav-link collapsed ${['/pending_personal_kyc', '/approved_personal_kyc', '/rejected_personal_kyc'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapsepersnolKyc" aria-expanded="false" aria-controls="collapsepersnolKyc">
                                <div className="nav-link-icon"><i className="fas fa-chalkboard-teacher"></i></div>
                                Personal KYC
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/pending_personal_kyc', '/approved_personal_kyc', '/rejected_personal_kyc'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapsepersnolKyc" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/pending_personal_kyc' ? 'active' : ''}`} to="/pending_personal_kyc"
                                        onClick={handleRemoveClass}
                                    >
                                        Pending KYC
                                    </Link>

                                    <Link className={`nav-link ${location?.pathname === '/approved_personal_kyc' ? 'active' : ''}`} to="/approved_personal_kyc"
                                        onClick={handleRemoveClass}>
                                        Approved KYC
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/rejected_personal_kyc' ? 'active' : ''}`} to="/rejected_personal_kyc"
                                        onClick={handleRemoveClass}>
                                        Rejected KYC
                                    </Link>
                                </nav>
                            </div>
                        </div>

                        {/* Entity KYC Manager */}
                        <div>
                            <div className={`nav-link collapsed ${['/pending_entity_kyc', '/approved_entity_kyc', '/rejected_entity_kyc'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseentityKyc" aria-expanded="false" aria-controls="collapseentityKyc">
                                <div className="nav-link-icon"><i className="fas fa-chalkboard-teacher"></i></div>
                                Entity KYC
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/pending_entity_kyc', '/approved_entity_kyc', '/rejected_entity_kyc'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseentityKyc" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/pending_entity_kyc' ? 'active' : ''}`} to="/pending_entity_kyc"
                                        onClick={handleRemoveClass}
                                    >
                                        Pending KYC
                                    </Link>

                                    <Link className={`nav-link ${location?.pathname === '/approved_entity_kyc' ? 'active' : ''}`} to="/approved_entity_kyc"
                                        onClick={handleRemoveClass}>
                                        Approved KYC
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/rejected_entity_kyc' ? 'active' : ''}`} to="/rejected_entity_kyc"
                                        onClick={handleRemoveClass}>
                                        Rejected KYC
                                    </Link>
                                </nav>
                            </div>
                        </div>


                        {/*  Buy Sell */}
                        <div>
                            <div className={`nav-link collapsed ${['/buysell', '/buysell-history'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseBuySell" aria-expanded="false" aria-controls="collapseBuySell">
                                <div className="nav-link-icon"><i className="fas fa-exchange-alt"></i></div>
                                Buy & Sell
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/buysell', '/buysell-history'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseBuySell" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/buysell' ? 'active' : ''}`} to="/buysell"
                                        onClick={handleRemoveClass}>
                                        Buy Sell
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/buysell-history' ? 'active' : ''}`} to="/buysell-history"
                                        onClick={handleRemoveClass}>
                                        History
                                    </Link>
                                </nav>
                            </div>
                        </div>


                        {/* Funds Deposit Management */}
                        <div>
                            <div className={`nav-link collapsed ${['/complete_deposit', '/pending_deposit'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseFundDeposit" aria-expanded="false" aria-controls="collapseFundDeposit">
                                <div className="nav-link-icon"><i className="fa-solid fa-filter-circle-dollar"></i></div>
                                Funds Deposit
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/complete_deposit', '/pending_deposit'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseFundDeposit" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/complete_deposit' ? 'active' : ''}`} to="/complete_deposit"
                                        onClick={handleRemoveClass}>
                                        Completed Deposit
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/pending_deposit' ? 'active' : ''}`} to="/pending_deposit"
                                        onClick={handleRemoveClass}>
                                        Pending Deposit
                                    </Link>
                                </nav>
                            </div>
                        </div>


                        {/* Funds Withdrawal Management */}
                        <div>
                            <div className={`nav-link collapsed ${['/completed_withdrawal', '/pending_withdrawal', '/cancelled_withdrawal'].includes(location?.pathname) ? 'active' : ''}`}
                                data-bs-toggle="collapse" data-bs-target="#collapseFundWithdrawal" aria-expanded="false" aria-controls="collapseFundWithdrawal">
                                <div className="nav-link-icon"><i className="fa-solid fa-filter-circle-dollar"></i></div>
                                Funds Withdrawal
                                <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </div>

                            <div className={`collapse ${['/completed_withdrawal', '/pending_withdrawal', '/cancelled_withdrawal'].includes(location?.pathname) ? 'show' : ''}`}
                                id="collapseFundWithdrawal" data-bs-parent="#accordionSidenav">
                                <nav className="sidenav-menu-nested nav">
                                    <Link className={`nav-link ${location?.pathname === '/completed_withdrawal' ? 'active' : ''}`} to="/completed_withdrawal"
                                        onClick={handleRemoveClass}>
                                        Completed Withdrawal
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/pending_withdrawal' ? 'active' : ''}`} to="/pending_withdrawal"
                                        onClick={handleRemoveClass}>
                                        Pending Withdrawal
                                    </Link>
                                    <Link className={`nav-link ${location?.pathname === '/cancelled_withdrawal' ? 'active' : ''}`} to="/cancelled_withdrawal"
                                        onClick={handleRemoveClass}>
                                        Cancelled Withdrawal
                                    </Link>
                                </nav>
                            </div>
                        </div>


                        {/* Withdrawal Multi Sig */}
                        <Link to="/withdrwalmultisig" className={`nav-link collapsed ${location?.pathname === "/withdrwalmultisig" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fa-solid fa-money-bill-transfer"></i></div>
                            Withdrawal Multi Sig
                        </Link>




                        {/* Currency Management */}
                        <Link to="/currency_management" className={`nav-link collapsed ${location?.pathname === "/currency_management" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fa-solid fa-indian-rupee-sign"></i></div>
                            Currency Management
                        </Link>


                        {/* Currency Management */}
                        <Link to="/currencypair" className={`nav-link collapsed ${location?.pathname === "/currencypair" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fa-solid fa-indian-rupee-sign"></i></div>
                            Currency Pair Management
                        </Link>



                        {/*  Trading Report */}
                        <Link to="/trading_report" className={`nav-link collapsed ${location?.pathname === "/trading_report" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fa fa-wave-square"></i></div>
                            Trading Report
                        </Link>


                        {/*  OrderBook */}
                        <Link to="/order_book" className={`nav-link collapsed ${location?.pathname === "/order_book" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fa-solid fa-book-atlas"></i></div>
                            OrderBook
                        </Link>


                        {/*  Support */}
                        <Link to="/support" className={`nav-link collapsed ${location?.pathname === "/support" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fas fa-headset"></i></div>
                            Support
                        </Link>


                        {/*  Notifications */}
                        <Link to="/notification" className={`nav-link collapsed ${location?.pathname === "/notification" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fa fa-bell"></i></div>
                            Notifications
                        </Link>

                        {/*  Banner Management */}
                        <Link to="/banner_management" className={`nav-link collapsed ${location?.pathname === "/banner_management" && "active"}`}
                            onClick={handleRemoveClass}>
                            <div className="nav-link-icon"><i className="fa fa-image"></i></div>
                            Banners
                        </Link>

                    </div>
                </div>
            </nav >
        </div >
    )
}

export default Sidebar;
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";
import DataTable from "react-data-table-component";
import VerifyKyc from "../KycDetails";
import { $ } from "react-jquery-plugin";
import { useNavigate } from "react-router-dom";

const PendingKyc = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);

  const navigate = useNavigate()
  const linkFollow = (row) => {
    return (
      <button className="verifybtn" onClick={() => { navigate('/kyc_detail', { state: row }) }}>
        View
      </button>
    );
  };


  const columns = [
    { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), },
    { name: "ID", wrap: true, selector: row => row._id, },
    { name: "FirstName", selector: row => row.first_name, },
    { name: "LastName", selector: row => row.last_name, },
    { name: "EmailId", wrap: true, selector: row => row.emailId, },
    { name: "Pan Number", wrap: true, selector: row => row.pancard_number, },
    { name: "Mobile No.", selector: row => row.mobileNumber, },
    { name: "Action", selector: linkFollow, },
  ];

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getKycdata('pending-kyc-user').then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result.data.reverse());
          setAllData(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };


  function searchObjects(e) {
    const keysToSearch = ["first_name", "last_name", "emailId", "pancard_number", "mobileNumber", "_id"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter(obj => {
      return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
    });
    setData(matchingObjects);
  }

  return (
    <>
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-12 mt-4">
                <div className="d-flex justify-content-between" >
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className=" fa fa-user-slash"></i>
                    </div>
                    Pending Kyc
                  </h1>
                  <button type="button" className="p-5 fw-bold text-light py-1 fs-6 bg-primary border border-info rounded-pill" onClick={()=>$('#editModal').modal('show')}>Edit</button> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">

              <div className="col-5">
                <input className="form-control" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
              </div>
              
              <div className="dropdown">
                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <i className="fa fa-download me-3"></i>Export
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                  <CSVLink data={data} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTable columns={columns} data={data} pagination />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>




{/* Edit Modal Pending KYC */}
<div className="modal" id='editModal' tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Details</h5>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form></form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Cancel</button>
            <button className="btn btn-primary" type="button" >Submit</button>
          </div> 
        </div>
      </div>
    </div>
    </>
  )
};
export default PendingKyc;

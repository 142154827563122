import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Support from "../Support";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { alertErrorMessage } from "../../CustomComponent/CustomAlert";
import LoaderHelper from "../../CustomComponent/LoaderHelper/LoaderHelper";

const Supportmessage = (props) => {
  const { id, email, description } = props;
  const [activeScreen, setActiveScreen] = useState('supportmessage');
  const [message, setMessage] = useState('');
  const [messageQuery, setMessageQuery] = useState([]);
  const [isRotating, setRotating] = useState(false);
  const chatEndRef = useRef(null);

  const handleMessageQuery = async (message, id) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.replyTicket(message, id);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        setMessage("");
        handleRefresh();
      } else {
        alertErrorMessage(result.msg);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error);
    }
  };

  const handleRefresh = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getMessageById(id);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        setMessageQuery(result?.data?.chat);
        scrollToBottom();
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error);
    } finally {
      setRotating(false);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 100);  // Add a slight delay to ensure the element is in the DOM
  };

  useEffect(() => {
    handleRefresh();
  }, [id]);

  return (
    activeScreen === 'supportmessage' ? (
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <Link to="" onClick={() => setActiveScreen('support')}>
                        <i className="fa fa-arrow-left"></i> 
                      </Link>&nbsp;
                      Support Message
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="container-xl px-4 mt-n10">
            <div className="row">
              <div className="col-xl-4">
                <div className="card mb-4">
                  <div className="card-header">
                    Description
                  </div>
                  {description?.length === 0 ? (
                    <h6 style={{ textAlign: 'center', padding: '164px 0' }}>
                      No Description Available
                    </h6>
                  ) : (
                    <div className="card-body mt-3">
                      <div className="messages">
                        {description}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-8">
                <div className="card mb-4">
                  <div className="card-header">
                    {email}
                    <button
                      className={`btn btn-sm btn-success mx-1 ${isRotating ? 'rotating' : ''}`}
                      onClick={() => { handleRefresh(); setRotating(true); }}
                      type="button"
                    >
                      Refresh
                    </button>
                  </div>
                  <div className="card-body mt-3">
                    <div className="messages">
                      {messageQuery?.length > 0 ? (
                        messageQuery.map((item, index) => (
                          <div key={index} className={`clip ${item?.message_by === 1 ? 'received' : 'sent'}`}>
                            <div className="text">{item?.message}</div>
                          </div>
                        ))
                      ) : null}
                      <div ref={chatEndRef} />
                    </div>
                    <div className="bottom">
                      <div className="cup">
                        <input
                          type="text"
                          id="message"
                          placeholder="Message..."
                          name="message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <button
                          className="send"
                          onClick={() => handleMessageQuery(message, id)}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    ) : (
      <Support />
    )
  );
};

export default Supportmessage;

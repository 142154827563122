import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import LoaderHelper from "../../../CustomComponent/LoaderHelper/LoaderHelper";
import { $ } from "react-jquery-plugin";
import EditModal from "./EditModal";


const TraderDetails = (props) => {

    const [userDetail, setUserDetail] = useState([]);
    const [walletdetails, setwalletdetails] = useState([]);
    const [status, setstatus] = useState();
    const [makerFee, setMakerFee] = useState();
    const [takerFee, setTakerFee] = useState();
    const location = useLocation();
    const userTradeData = location.state?._id

    const [formData, setFormData] = useState([{
        firstName: userTradeData?.firstName,
        lastName: userTradeData?.lastName,
        profilePicture: "",
        status: userTradeData?.status,
        _id: userTradeData?._id,
    }])

    const [modalFields, setModalFields] = useState([]);
    const [modalTitle, setModalTitle] = useState('');

    const handleUserData = async () => {
        try {
            const result = await AuthService.getuserDetails(location.state?._id);
            if (result?.success) {
                setUserDetail(result.data);
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage("An error occurred while fetching user details.");
        }
    };

    const submitEditedDetails = () => {
        // document.getElementById('editModal').classList.remove('show');
        $('#edit_trade_modal').modal('hide');
        console.log('submittedData', formData)

        return

        LoaderHelper.loaderStatus(true);
        try {
            const result = AuthService.api(formData);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                // $('#edit_trade_modal').modal('hide');
            } else {
                alertErrorMessage(result?.message);
            };
        } catch (error) {
            alertErrorMessage(error?.message);
        }
        finally { LoaderHelper.loaderStatus(false) };
    }

    const PriceFormat = (row) => {
        return row?.fee && parseFloat(row?.fee?.toFixed(5));
    };

    const columns = [
        { name: "Date/Time", selector: row => moment(row?.updatedAt).format("MMM Do YYYY"), },
        { name: "Currency", selector: row => row.currency, },
        { name: "Fee", selector: PriceFormat },
        { name: "Type", selector: row => row.order_type, },
        { name: "Price", selector: row => row.price, },
        { name: "Quantity", selector: row => row.quantity, },
        { name: "Side", selector: row => row.side, },
        { name: "TDS", selector: row => row.tds, },
    ]

    // ******** Wallet History Table ************//
    const walletdetailscolumns = [
        { name: "Assets", selector: row => row.short_name, },
        { name: "Balance", selector: row => row.balance, },
        { name: "Locked Balance", selector: row => row.locked_balance, },
    ]

    const handleTransferhistory = async () => {
        await AuthService.transferhistory(location.state?._id).then(async result => {
            if (result?.success) {
                try {
                    // setuserTradeDetails(result?.data);
                } catch (error) {
                    // console.log('error', `${error}`);
                }
            } else {
                // alertErrorMessage(result.message)
            }
        });
    }
    const userWallet = async () => {
        await AuthService.userWallet(location.state?._id).then(async result => {
            if (result?.success) {
                try {
                    setwalletdetails(result?.data);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                // alertErrorMessage(result.message)
            }
        });
    }





    const HandleStatus = (Status) => {
        setstatus(Status);
        if (!Status) {
            setMakerFee(0)
            setTakerFee(0)
        }
    };
    useEffect(() => {
        if (makerFee > 0 || takerFee > 0) {
            setstatus(true)
        } else {
            setMakerFee(0);
            setTakerFee(0);
            setstatus(false)
        }

    }, [makerFee, takerFee]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const openModal = (fields, title) => {
        setModalFields(fields);
        setModalTitle(title);
        $('#editModal').modal('show');
    };

    const personalFields = [
        { label: 'First Name', name: 'firstName', type: 'text', placeholder: 'Enter First Name', id: 'inputFirstName' },
        { label: 'Last Name', name: 'lastName', type: 'text', placeholder: 'Enter Last Name', id: 'inputLastName' },
        { label: 'Email', name: 'email', type: 'email', placeholder: 'Enter Email', id: 'inputEmail' },
        { label: 'Mobile Number', name: 'phone', type: 'text', placeholder: 'Enter Phone', id: 'inputPhone' },
        { label: 'Referral Code', name: 'Referral Code', type: 'text', placeholder: 'Enter Referral Code', id: 'inputReferralCode' },
        { label: 'Status', name: 'status', type: 'text', placeholder: 'Enter Status', id: 'inputStatus' },
        { label: 'Kyc Type', name: 'kycType', type: 'text', placeholder: 'Enter Kyc Type', id: 'inputKycType' },
        { label: 'Date of Birth', name: 'dob', type: 'text', placeholder: 'Enter Date of Birth', id: 'inputDob' },
        { label: 'Address', name: 'address', type: 'text', placeholder: 'Enter Address', id: 'inputAddress' },
        { label: 'City Name', name: 'city', type: 'text', placeholder: 'Enter City Name', id: 'inputCity' },
        { label: 'Zip Code', name: 'zipCode', type: 'text', placeholder: 'Enter Zip Code', id: 'inputZipCode' },
        { label: 'State Name', name: 'state', type: 'text', placeholder: 'Enter State Name', id: 'inputState' },
        { label: 'Country Name', name: 'country', type: 'text', placeholder: 'Enter Country Name', id: 'inputCountry' },
    ];

    const bankAccountFields = [
        { label: 'Bank Name', name: 'bankName', type: 'text', placeholder: 'Enter Bank Name', id: 'inputBankName' },
        { label: 'Branch Name', name: 'branchName', type: 'text', placeholder: 'Enter Branch Name', id: 'inputBranchName' },
        { label: 'Account Holder Name', name: 'accountHolderName', type: 'text', placeholder: 'Enter Account Holder Name', id: 'inputAccountHolderName' },
        { label: 'Account Type', name: 'accountType', type: 'text', placeholder: 'Enter Account Type', id: 'inputAccountType' },
        { label: 'Bank Account Number', name: 'bankAccountNumber', type: 'text', placeholder: 'Enter Bank Account Number', id: 'inputBankAccountNumber' },
        { label: 'IFSC Code', name: 'ifscCode', type: 'text', placeholder: 'Enter IFSC Code', id: 'inputIFSCCode' },
    ];

    const upiAccountFields = [
        { label: 'Name', name: 'upiName', type: 'text', placeholder: 'Enter Name', id: 'inputUPIName' },
        { label: 'UPI VPA ID', name: 'upiVpaId', type: 'text', placeholder: 'Enter UPI VPA ID', id: 'inputUPIVpaId' },
    ];

    const nomineeFields = [
        { label: ' Name', name: 'nomineeName', type: 'text', placeholder: 'Enter Name', id: 'inputNomineeName' },
        { label: 'Relation', name: 'relation', type: 'text', placeholder: 'Enter Relation', id: 'inputRelation' },
        { label: 'Date of Birth', name: 'nomineeDob', type: 'text', placeholder: 'Enter Date of Birth', id: 'inputnomineeDob' },
        { label: 'Aadhaar Number', name: 'nomineeAadhaar', type: 'text', placeholder: 'Enter Aadhaar Number', id: 'inputnomineeAadhaar' },
    ]




    useEffect(() => {
        handleUserData();
    }, [])



    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <form className="form-data" >
                        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div className="container-xl px-4">
                                <div className="page-header-content pt-4">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto mt-4">
                                            <h1 className="page-header-title">
                                                <Link to="/trade_list" className="page-header-icon">
                                                    <i className="fa fa-arrow-left" ></i>
                                                </Link>
                                                {userDetail?.kyc?.first_name} {userDetail?.kyc?.last_name}
                                            </h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="container-xl px-4 mt-n10" >
                            <div className="row" >

                                <div className="col-xl-4">
                                    <div className="card mb-4 mb-xl-0">
                                        <div className="card-body py-5">
                                            <div className="text-center">

                                                <img
                                                    src={userDetail?.document_front_image ? `${ApiConfig.appUrl}${userDetail?.document_front_image}` : "assets/img/illustrations/profiles/profile-5.png"}
                                                    alt=""
                                                    className="img-account-profile rounded-circle mb-4"
                                                    onError={(e) => e.target.src = "assets/img/illustrations/404-error.svg"}
                                                />

                                                <h3 className="fw-bolder fs-2 mb-0">
                                                    {userDetail?.kyc?.emailId}
                                                </h3>

                                            </div>
                                            <div className=" py-5 px-4 ">
                                                <ul className="nav nav-pills flex-column" id="cardTab" role="tablist">
                                                    <li className="nav-item  ">
                                                        <a className="menu-link d-flex nav-link active" id="personal-pill" href="#personalPill" data-bs-toggle="tab" role="tab" aria-controls="personal" aria-selected="true">
                                                            <span className="menu-bullet"><span className="bullet"></span>
                                                            </span><span className="menu-title">  Personal Information </span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item  ">
                                                        <a className="menu-link d-flex nav-link" id="Verification-pill" href="#VerificationPill" data-bs-toggle="tab" role="tab" aria-controls="Verification" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                        </span><span className="menu-title">  KYC Verification</span></a>
                                                    </li>
                                                    <li className="nav-item ">
                                                        <a className="menu-link d-flex nav-link" id="bankaccount-pill" href="#bankaccountPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                        </span><span className="menu-title">  Bank Account Details</span></a>
                                                    </li>
                                                    <li className="nav-item ">
                                                        <a className="menu-link d-flex nav-link" id="upiaccount-pill" href="#upiaccountPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                        </span><span className="menu-title">  UPI Account Details</span></a>
                                                    </li>
                                                    <li className="nav-item ">
                                                        <a className="menu-link d-flex nav-link" id="permission-pill" href="#permissionPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                        </span><span className="menu-title">  Permission Details</span></a>
                                                    </li>

                                                    <li className="nav-item ">
                                                        <a className="menu-link d-flex nav-link" id="twoFactorAuthentication-pill" href="#twoFactorAuthenticationPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                        </span><span className="menu-title">  Two Factor Authentication Details</span></a>
                                                    </li>
                                                    <li className="nav-item ">
                                                        <a className="menu-link d-flex nav-link" id="nominee-pill" href="#nomineePill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                        </span><span className="menu-title">  Nominee Details</span></a>
                                                    </li>
                                                    <li className="nav-item ">
                                                        <a className="menu-link d-flex nav-link" id="Transitions-pill" href="#TransitionsPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                        </span><span className="menu-title">  User Trade Details</span></a>
                                                    </li>
                                                    <li className="nav-item ">
                                                        <a className="menu-link d-flex nav-link" id="Transitions-pill" href="#walletdetails" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span className="menu-bullet"><span className="bullet"></span>
                                                        </span><span className="menu-title">  User Wallet Details</span></a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8" >
                                    <div className="tab-content tab-content-card" id="cardPillContent">

                                        {/* personal INformation */}
                                        <div className="tab-pane card show active" id="personalPill" role="tabpanel" aria-labelledby="personal-pill">
                                            <div className="card-header">Personal Information

                                                <div className="d-flex justify-content-end" >
                                                   
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => openModal(personalFields, 'Edit Personal Information')}
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="card-body" >
                                                <div className="profile_data py-4 px-4" >

                                                    <div className="row">
                                                        <span className="col-3" >User Id: </span>
                                                        <strong className="col text-dark ">{userDetail?.user?.uin}</strong>
                                                    </div>
                                                    <hr className="my-3" />

                                                    <div className="row">
                                                        <span className="col-3" >First Name: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.first_name}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >Last Name: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.last_name}</strong>
                                                    </div>

                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >Mobile Number: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.mobileNumber}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >Referral Code: </span>
                                                        <strong className="col text-dark ">{userDetail?.user?.referral_code}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >Status: </span>
                                                        <strong className="col text-dark ">{userDetail?.user?.status}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >Kyc Type: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.sub_kyc_type}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >Date of Birth: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.dob}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >Address: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.address}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >City Name: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.city}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >Zip Code: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.zip_code}</strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row">
                                                        <span className="col-3" >State Name: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.state}</strong>
                                                    </div>
                                                    <hr className="my-3" />

                                                    <div className="row">
                                                        <span className="col-3" >Country Name: </span>
                                                        <strong className="col text-dark ">{userDetail?.kyc?.country}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* KYC Verification */}
                                        <div className="tab-pane card" id="VerificationPill" role="tabpanel" aria-labelledby="Verification-pill">
                                            <div className="card-header">KYC Verification Details
                                                <div className="d-flex justify-content-end" >

                                                </div>
                                            </div>
                                            <div className="card-body" >
                                                <div className="profile_data py-4 px-4" >
                                                    <div className="row" >
                                                        <span className="col-3" >Verification Status: </span>

                                                        {userDetail?.user?.kycVerified == 2 ?
                                                            <strong className=" col text-success  ">Verified</strong>
                                                            : userDetail?.user?.kycVerified == 3 ?
                                                                <strong className=" col text-danger">Rejected</strong>
                                                                : <strong className="col text-warning">Pending</strong>
                                                        }

                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >Submited Date:</span>
                                                        <strong className=" col">{userDetail?.kyc?.createdAt && moment(userDetail?.kyc?.createdAt).format('Do MMMM YYYY')}</strong>
                                                    </div>

                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >KYC Type:</span>
                                                        <strong className=" col"> {userDetail?.kyc?.kyc_type} </strong>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="row" > <span className="col-3" >Document Type:</span>
                                                        <strong className=" col"> {userDetail?.kyc?.document_type} ({userDetail?.kyc?.document_number}) </strong>
                                                    </div>
                                                    <hr className="my-3" />

                                                    <div className="row" > <span className="col-3" >Pan Card Number:</span>
                                                        <strong className=" col"> {userDetail?.kyc?.pancard_number} </strong>
                                                    </div>
                                                    <hr className="my-3" />


                                                </div>
                                            </div>
                                        </div>

                                        {/* Bank Account Details*/}
                                        <div className="tab-pane card" id="bankaccountPill" role="tabpanel" aria-labelledby="bankaccount-pill">
                                            <div className="card-header">Bank Account Details
                                                <div className="d-flex justify-content-end" >

                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => openModal(bankAccountFields, 'Edit Bank Information')}
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="profile_data py-5 px-4" >

                                                <div className="row" >
                                                    <span className="col-3" >Bank Name: </span>
                                                    <strong className=" col  "> {userDetail?.bank_details?.bank_name || "----"}  </strong>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >Branch Name:</span>
                                                    <strong className=" col"> {userDetail?.bank_details?.branch_name || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />

                                                <div className="row" > <span className="col-3" >Account Holder Name:</span>
                                                    <strong className=" col"> {userDetail?.bank_details?.account_holder_name || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >Account Type:</span>
                                                    <strong className=" col"> {userDetail?.bank_details?.account_type || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >Bank Account Number:</span>
                                                    <strong className=" col">{userDetail?.bank_details?.account_number || "----"}</strong>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >IFSC Code:</span>
                                                    <strong className=" col"> {userDetail?.bank_details?.ifsc || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />
                                            </div>
                                        </div>


                                        {/* UPI Account Details */}
                                        <div className="tab-pane card" id="upiaccountPill" role="tabpanel" aria-labelledby="upiaccount-pill">
                                            <div className="card-header">UPI Account Details

                                                <div className="d-flex justify-content-end" >
                                                    
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => openModal(upiAccountFields, 'Edit UPI Information')}
                                                    >
                                                        Edit
                                                    </button>

                                                </div>
                                            </div>

                                            <div className="profile_data py-5 px-4" >

                                                <div className="row" >
                                                    <span className="col-3" > Name: </span>
                                                    <strong className=" col  "> {userDetail?.upi_details?.bank_name || "----"}  </strong>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >UPI VPA Id:</span>
                                                    <strong className=" col"> {userDetail?.upi_details?.branch_name || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />

                                                <div className="row" > <span className="col-3" >Email OTP:</span>
                                                    <strong className=" col"> {userDetail?.upi_details?.account_holder_name || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />

                                            </div>
                                        </div>

                                        {/* Permission Details */}
                                        <div className="tab-pane card" id="permissionPill" role="tabpanel" aria-labelledby="permission-pill">
                                            <div className="card-header">Permission Details
                                                <div className="d-flex justify-content-end" >

                                                </div>
                                            </div>

                                            <div className="profile_data py-5 px-4" >

                                                <div className="row" >
                                                    <span className="col-9" ><label htmlFor="forBuySell">Buy Sell : </label></span>
                                                    {/* <input type="checkbox" name="" id="forBuySell" /> */}
                                                    <label className="switch">
                                                        <input type="checkbox" name="" id="forBuySell" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-9" ><label htmlFor="forSpot">Spot :</label></span>
                                                    {/* <strong className=" col"> {userDetail?.bank_details?.branch_name || "----"} </strong> */}
                                                    <label className="switch" htmlFor="forSpot">
                                                        <input type="checkbox" name="" id="forSpot" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />

                                                <div className="row" > <span className="col-9" ><label htmlFor="forP2P">P2P :</label></span>
                                                    {/* <strong className=" col"> {userDetail?.bank_details?.account_holder_name || "----"} </strong> */}
                                                    <label className="switch" htmlFor="forP2P">
                                                        <input type="checkbox" name="" id="forP2P" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-9" ><label htmlFor="forDeposit">Deposit :</label></span>
                                                    {/* <strong className=" col"> {userDetail?.bank_details?.account_type || "----"} </strong> */}
                                                    <label className="switch" htmlFor="forDeposit">
                                                        <input type="checkbox" name="" id="forDeposit" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-9" ><label htmlFor="forWithdrawal"> Withdrawal :</label></span>
                                                    {/* <strong className=" col">{userDetail?.bank_details?.account_number || "----"}</strong> */}
                                                    <label className="switch" htmlFor="forWithdrawal">
                                                        <input type="checkbox" name="" id="forWithdrawal" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />

                                            </div>
                                        </div>

                                        {/* Two Factor Authentication Details */}

                                        <div className="tab-pane card" id="twoFactorAuthenticationPill" role="tabpanel" aria-labelledby="twoFactorAuthentication-pill">
                                            <div className="card-header">Two Factor Authentication Details
                                                <div className="d-flex justify-content-end" >

                                                </div>
                                            </div>

                                            <div className="profile_data py-5 px-4" >

                                                <div className="row" > <span className="col-9" ><label htmlFor="forAuthenticatorApp"> Authenticator App :</label></span>
                                                    {/* <strong className=" col">{userDetail?.bank_details?.account_number || "----"}</strong> */}
                                                    <label className="switch" htmlFor="forAuthenticatorApp">
                                                        <input type="checkbox" name="" id="forAuthenticatorApp" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-9" ><label htmlFor="forEmailOTP">Email OTP :</label></span>
                                                    {/* <strong className=" col"> {userDetail?.bank_details?.branch_name || "----"} </strong> */}
                                                    <label className="switch" htmlFor="forEmailOTP">
                                                        <input type="checkbox" name="" id="forEmailOTP" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />

                                                <div className="row" > <span className="col-9" ><label htmlFor="forMobileOTP">Mobile OTP :</label></span>
                                                    {/* <strong className=" col"> {userDetail?.bank_details?.account_holder_name || "----"} </strong> */}
                                                    <label className="switch" htmlFor="forMobileOTP">
                                                        <input type="checkbox" name="" id="forMobileOTP" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-9" ><label htmlFor="forNone">None :</label></span>
                                                    {/* <strong className=" col"> {userDetail?.bank_details?.account_type || "----"} </strong> */}
                                                    <label className="switch" htmlFor="forNone">
                                                        <input type="checkbox" name="" id="forNone" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <hr className="my-3" />


                                            </div>
                                        </div>

                                        {/* Nominee Details */}
                                        <div className="tab-pane card" id="nomineePill" role="tabpanel" aria-labelledby="nominee-pill">
                                            <div className="card-header">Nominee Details
                                                <div className="d-flex justify-content-end" >
                                                   
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => openModal(nomineeFields, 'Edit Personal Information')}
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="profile_data py-5 px-4" >

                                                <div className="row" >
                                                    <span className="col-3" > Name: </span>
                                                    <strong className=" col  "> {userDetail?.nominee_details?.bank_name || "----"}  </strong>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" > Relation:</span>
                                                    <strong className=" col"> {userDetail?.nominee_details?.branch_name || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />

                                                <div className="row" > <span className="col-3" >Date of Birth:</span>
                                                    <strong className=" col"> {userDetail?.nominee_details?.account_holder_name || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" > <span className="col-3" >Aadhaar Number:</span>
                                                    <strong className=" col"> {userDetail?.nominee_details?.account_type || "----"} </strong>
                                                </div>
                                                <hr className="my-3" />

                                            </div>
                                        </div>


                                        {/* User Trade Details */}
                                        <div className="tab-pane card" id="TransitionsPill" role="tabpanel" aria-labelledby="Transitions-pill">
                                            <div className="card-header">User Trade Details
                                                <div className="d-flex justify-content-end" ><button type="button" className="p-5 fw-bold text-light py-1 fs-6 bg-primary border border-info rounded-pill" onClick={handleChange}>Edit</button> </div>


                                            </div>

                                            <div className="card-body" >
                                                <form className="row">
                                                    <div className="col-12" >
                                                    <table className="" width="100%" >
                                                            <DataTable columns={walletdetailscolumns} data={walletdetails} />
                                                        </table>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>

                                        {/* walletdetails*/}
                                        <div className="tab-pane card" id="walletdetails" role="tabpanel" aria-labelledby="walletdetails">
                                            <div className="card-header">User Wallet Details
                                                <div className="d-flex justify-content-end" ><button type="button" className="p-5 fw-bold text-light py-1 fs-6 bg-primary border border-info rounded-pill" onClick={handleChange}>Edit</button> </div>
                                                {
                                                    walletdetails?.length === 0 ? "" :
                                                        <div className="dropdown">
                                                            <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                            <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                                <CSVLink data={walletdetails} className="dropdown-item">Export as CSV</CSVLink>
                                                            </div>
                                                        </div>
                                                }

                                            </div>
                                            <div className="card-body" >
                                                <form className="row">
                                                    <div className="col-12" >
                                                        <table className="" width="100%" >
                                                            <DataTable columns={walletdetailscolumns} data={walletdetails} />
                                                        </table>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        {/* Master Admin */}
                                        <div className="tab-pane card" id="masterAdmin" role="tabpanel" aria-labelledby="bankaccount-pill">
                                            <div className="card-header">Master Trader</div>

                                            <div className="profile_data py-5 px-4" >
                                                <div className="row" >
                                                    <label className="small mb-1" for="Maker">Maker Fee </label>
                                                    <input type="number" className="form-control  form-control-solid" id="Maker" placeholder="Maker Fee" name="firstName" value={makerFee} onChange={(e) => setMakerFee(e.target.value)} onWheel={(e) => e.target.Blur()} />
                                                </div>
                                                <hr className="my-3" />
                                                <div className="row" >
                                                    <label className="small mb-1" for="taker">Taker Fee </label>
                                                    <input type="number" className="form-control  form-control-solid" id="taker" placeholder="Taker Fee " name="firstName" value={takerFee} onChange={(e) => setTakerFee(e.target.value)} onWheel={(e) => e.target.Blur()} /></div>
                                                <hr className="my-3" />
                                                <div className="row" > <label className="small mb-1" for="taker">Status </label><div>
                                                    <button type="button" className={`btn  btn-sm  me-2 ${status ? 'btn-success' : 'btn-outline-success'}`} onClick={() => HandleStatus(true)} >Active</button>
                                                    <button type="button" className={`btn  btn-sm  me-2 ${status ? 'btn-outline-danger' : 'btn-danger'}`} onClick={() => HandleStatus(false)}  >Inactive</button></div>  </div>
                                                <hr className="my-3" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </main>
            </div>





            {/* Edit Information Model Box */}

            <EditModal
                title={modalTitle}
                fields={modalFields}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={submitEditedDetails}
                modalId="editModal"
            />


        </>

    )


}

export default TraderDetails;
import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";



const SubAdminList = () => {

    const navigate = useNavigate();

    const [subAdminList, setSubAdminList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [skip, setSkip] = useState(0);
    const limit = 20;

    const handleSubadmin = async () => {
        try {
            const result = await AuthService.getSubAdminList();
            if (result?.success) {
                // let filterData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
                setFilterData(result?.data);
                setSubAdminList(result?.data);
                setTotalDataLength(result?.data?.length);
            } else {
                alertErrorMessage(result?.message);
            };
        } catch (error) {
            alertErrorMessage(error?.message);
        }
        finally { LoaderHelper.loaderStatus(false) };
    };


    const handleStatus = async (userId, status) => {
        try {
            const result = await AuthService.handleSubadminStatus(userId, status);
            if (result?.success) {
                handleSubadmin();
            } else {
                alertErrorMessage(result?.message)
            }
        } catch (error) {
            alertErrorMessage(error?.message);
        }
    }

    const deleteSubAdmin = async (userId) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.deleteSubAdminList(userId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleSubadmin();
            } else {
                alertErrorMessage(result.message)
            }
        } catch (error) {
            alertErrorMessage(error?.message)
        }
        finally { LoaderHelper.loaderStatus(false) };
    };


    const columns = [
        {
            name: "Date/Time",
            cell: (row) => (
                <div className="justify-content-start">
                    <span>
                        {moment(row.createdAt).format("DD/MM/YYYY")} <br />
                        <small>{moment(row.createdAt).format("HH:mm:ss")}</small>
                    </span>
                </div>
            ),
            sortable: false,
        },
        {
            name: "First Name",
            selector: (row) => row.first_name,
            wrap: true,
            sortable: true,
        },
        {
            name: "Last Name",
            selector: (row) => row.last_name,
            wrap: true,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email_or_phone,
            wrap: true,
            sortable: true,
        },
        {
            name: "Status",
            sortable: true,
            cell: (row) => (
                <button
                    className={`btn btn-sm ${row?.status === "Active" ? "btn-success" : "btn-danger"}`}
                    style={{ marginLeft: "20px" }}
                    onClick={() => handleStatus(row?._id, row?.status === "Inactive" ? "Active" : "Inactive")}
                >
                    {row?.status === "Active" ? "Active" : "Inactive"}
                </button>
            ),
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <button
                        className="btn btn-dark btn-sm me-2"
                        title="Edit"
                        onClick={() => navigate('/edit_sub_admin', { state: row })}
                    >
                        <i className="fa-solid fa-pen"></i>
                    </button>
                    <button
                        className="btn btn-danger btn-sm"
                        title="Delete"
                        onClick={() => deleteSubAdmin(row?._id)}
                    >
                        <i className="fa-solid fa-trash"></i>
                    </button>
                </>
            ),
        },
    ];



    const searchObjects = (e) => {
        const keysToSearch = ["first_name", "email_or_phone", "last_name", "createdAt", "status"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = filterData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setSubAdminList(matchingObjects);
    };


    useEffect(() => {
        handleSubadmin()
    }, []);

    const handlePagination = (action) => {
        switch (action) {
            case 'prev':
                if (skip - limit >= 0) {
                    setSkip(skip - limit);
                }
                break;
            case 'next':
                if (skip + limit < totalDataLength) {
                    setSkip(skip + limit);
                }
                break;
            case 'first':
                setSkip(0);
                break;
            case 'last':
                const lastPageSkip = Math.max(totalDataLength - limit, 0);
                setSkip(lastPageSkip);
                break;
            default:
                break;
        }
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="d-flex justify-content-between mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fas fa-user-shield"></i></div>
                                        Sub Admin List
                                    </h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        {
                            subAdminList?.length > 0 &&
                            <div className="card-header d-flex justify-content-end">
                                <div className="col-3">
                                    <input className="form-control " id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                                </div>
                            </div>
                        }
                        <div className="g_start m_card overflow-hidden p-0">
                            <div className="m_table" >
                                <div className="table-responsive h-auto ">
                                    <DataTable
                                        className="table table-hover"
                                        columns={columns}
                                        data={subAdminList}
                                        noDataComponent={
                                            <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                        }
                                    />
                                    {
                                        subAdminList?.length > 0 &&
                                        <div className="hVPalX gap-2" >
                                            <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                            <div className="sc-eAKtBH gVtWSU">
                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>

                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

    )
}

export default SubAdminList;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../services/ApiIntegration/AuthService";
import LoaderHelper from '../../CustomComponent/LoaderHelper/LoaderHelper'
import { alertErrorMessage } from "../../CustomComponent/CustomAlert";


const Dashboard = () => {

    const [dashboardData, setDashboardData] = useState([]);

    const dashboardAPI = async () => {
        try {
            const result = await AuthService.getTotaluser();
            if (result.success) {
                setDashboardData(result?.data);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error.message || 'An error occurred');
        }
    };


    useEffect(() => {
        dashboardAPI();
    }, []);

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-th" ></i></div>
                                        Dashboard
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="row">
                        
                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-purple text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Total Traders</div>
                                            <div className="display-4 fw-bold">
                                                {dashboardData?.total_traders || 0}
                                            </div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>
                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/trade_list" >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-pink text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Total Withdrawal</div>
                                            <div className="display-4 fw-bold">{dashboardData?.total_withdrawal || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>
                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-orange text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Total Deposit</div>
                                            <div className="display-4 fw-bold">{dashboardData?.total_deposit || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-yellow text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Kyc Pending</div>
                                            <div className="display-4 fw-bold">{dashboardData?.kyc_pending || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-red text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Kyc Rejected</div>
                                            <div className="display-4 fw-bold">{dashboardData?.kyc_rejected || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-green text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Kyc Approved</div>
                                            <div className="display-4 fw-bold">{dashboardData?.kyc_approved || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-cyan text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Total Help Desk</div>
                                            <div className="display-4 fw-bold">{dashboardData?.total_help_desk || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-dark text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Close Help Desk</div>
                                            <div className="display-4 fw-bold">{dashboardData?.closed_help_desk || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-teal text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Active Users</div>
                                            <div className="display-4 fw-bold">{dashboardData?.active_users || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-secondary text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Total Balance</div>
                                            <div className="display-4 fw-bold">{dashboardData?.total_balance || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-primary text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Total Locked Balance</div>
                                            <div className="display-4 fw-bold">{dashboardData?.total_locked_balance || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-xl-3 mb-4">
                            <div className="card bg-orange text-white h-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-3">
                                            <div className="text-white-75">Total Available Balance</div>
                                            <div className="display-4 fw-bold">{dashboardData?.total_available_balance || 0}</div>
                                        </div>
                                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                    </div>
                                </div>

                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                    <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </main>
        </div >
    )
}
export default Dashboard;
import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { $ } from "react-jquery-plugin";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";


const KycDetails = () => {

  const [rejectReason, setRejectReason] = useState("");
  const [kycDetails, setKycDetails] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "rejectReason":
        setRejectReason(event.target.value);
        break;
    }
  };

  const handleData = async () => {
    await AuthService.getKycDetail(location.state?._id).then(async (result) => {
      if (result.success) {
        try {
          setKycDetails(result.data);
        } catch (error) {
          alertErrorMessage("Unauthorized");
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const verifyIdentity = async (id, status, reason) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getverifyidentity(id, status, reason).then(
      async (result) => {
        if (result?.success) {
          LoaderHelper.loaderStatus(false);
          try {
            alertSuccessMessage("user Kyc successfully Verified");
            $("#rejectmodal").modal("hide");
            navigate("/pending_kyc");
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title">
                        <Link to="/pending_kyc" className="page-header-icon" >
                          <i className="fa fa-arrow-left"></i>
                        </Link>
                        Hi, {kycDetails?.first_name}
                      </h1>
                    </div>
                    <div className="col-auto mt-4">
                      <div className="row">
                        <div className="d-flex">
                          <button className="btn btn-danger btn-block" data-bs-toggle="modal" data-bs-target="#rejectmodal" type="button" >Reject</button>
                          <button className="btn mx-2 btn-success btn-block" type="button" onClick={() => verifyIdentity(location.state?._id, 2)}>
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="container-xl px-4 mt-n10">
              <div className="row">
                <div className="col-xl-4 mb-4">
                  <div className="card mb-4 mb-xl-0">
                    <div className="card-body py-5 pb-0">

                      <div className="text-center">
                        <img
                          src={!kycDetails?.user_selfie ? "assets/img/illustrations/profiles/profile-5.png" : ApiConfig?.appUrl + kycDetails?.user_selfie} alt="" className="img-account-profile rounded-circle mb-4"
                          onError={(e) => {
                            e.target.src = "assets/img/illustrations/profiles/profile-5.png";
                          }}
                        />

                        <h3 className="fw-bolder fs-2 mb-0">
                          {kycDetails?.first_name}
                        </h3>
                      </div>

                      <div className="doc_img py-5 px-4 my-4">
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">First Name:</label>
                          <div className="col-lg-7">
                            <span className="fw-bolder fs-6 text-dark">{kycDetails?.first_name}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Last Name:</label>
                          <div className="col-lg-7">
                            <span className="fw-bolder fs-6 text-dark">{kycDetails?.last_name}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Mobile Number:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">{kycDetails?.mobileNumber}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Kyc Type:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">{kycDetails?.sub_kyc_type}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Address:</label>
                          <div className="col-lg-7 fv-row">
                            <span className="fw-bold fs-6 text-dark">
                              {kycDetails?.address}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Zip Code:</label>
                          <div className="col-lg-7 fv-row">
                            <span className="fw-bold fs-6 text-dark">
                              {kycDetails?.zip_code}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">City:</label>
                          <div className="col-lg-7 fv-row">
                            <span className="fw-bold fs-6 text-dark">{kycDetails?.city}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">State:</label>
                          <div className="col-lg-7 fv-row">
                            <span className="fw-bold fs-6 text-dark">{kycDetails?.state}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Country:</label>
                          <div className="col-lg-7 fv-row">
                            <span className="fw-bold fs-6 text-dark">{kycDetails?.country}</span>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted"> Date of Birth:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">
                              {kycDetails?.dob}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">Registration Date:</label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark text-hover-primary">
                              {moment(kycDetails?.createdAt).format("Do MMMM YYYY")}
                            </span>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card card-header-actions mb-4">
                    <div className="card-body">
                      <div className="row">

                        <div className="col-6  mb-3">
                          <div className="doc_img">
                            <div className="row mb-3">
                              <div className="col"> {kycDetails?.document_type} <small> (Front) </small> </div>
                            </div>
                            <div className="ratio ratio-16x9">
                              <img
                                src={kycDetails?.document_front_image ? `${ApiConfig.appUrl}${kycDetails?.document_front_image}` : "assets/img/illustrations/profiles/nophoto.png"}
                                alt=""
                                className="w-100 cursor_pointer"
                                onError={(e) => e.target.src = "assets/img/illustrations/404-error.svg"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <div className="doc_img">
                            <div className="row mb-3">
                              <div className="col">{kycDetails?.document_type}  <small> (Back) </small></div>
                            </div>
                            <div className="ratio ratio-16x9">
                              <img
                                src={kycDetails?.document_back_image ? `${ApiConfig.appUrl}${kycDetails?.document_back_image}` : "assets/img/illustrations/profiles/nophoto.png"}
                                alt=""
                                className="w-100 cursor_pointer"
                                onError={(e) => e.target.src = "assets/img/illustrations/404-error.svg"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="doc_img">
                          <div className="row">
                            <div className="col">{kycDetails?.document_type} No. : {kycDetails?.document_number}</div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-4">
                        <div className="col-6  mb-3">
                          <div className="doc_img">
                            <div className="row mb-3">
                              <div className="col"> Pan Card </div>
                            </div>
                            <div className="ratio ratio-16x9">
                              <img
                                src={kycDetails?.pancard_image ? `${ApiConfig.appUrl}${kycDetails?.pancard_image}` : "assets/img/illustrations/profiles/nophoto.png"}
                                alt=""
                                className="w-100 cursor_pointer"
                                onError={(e) => e.target.src = "assets/img/illustrations/404-error.svg"}
                              />
                            </div>
                          </div>
                        </div>


                        <div className="col-6  mb-3">
                          <div className="doc_img">
                            <div className="row mb-3">
                              <div className="col"><small>Selfie</small></div>
                            </div>
                            <div className="ratio ratio-16x9">
                              <img
                                src={kycDetails?.user_selfie ? `${ApiConfig.appUrl}${kycDetails?.user_selfie}` : "assets/img/illustrations/profiles/nophoto.png"}
                                alt=""
                                className="w-100 cursor_pointer"
                                onError={(e) => e.target.src = "assets/img/illustrations/404-error.svg"}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="doc_img">
                          <div className="row">
                            <div className="col">PanCardNo: {kycDetails?.pancard_number}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>

      <div className="modal" id="rejectmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Are You Sure{" "}<>{kycDetails?.first_name}</>{" "}?</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group mb-4 ">
                  <label className="small mb-1"> Enter Reason </label>
                  <textarea className="form-control form-control-solid" rows="7" placeholder="Enter your message.." value={rejectReason} name="rejectReason" onChange={handleInputChange}></textarea>
                </div>
                <button className="btn btn-danger btn-block w-100" type="button" disabled={!rejectReason} 
                onClick={() =>verifyIdentity(location.state?._id, 3, rejectReason)}>
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>

  );
};

export default KycDetails;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../ProfileContext";
import Swal from "sweetalert2";
import { alertErrorMessage } from "../CustomAlert";

const Header = () => {
    const navigate = useNavigate();

    const [,updateProfileState] = useContext(ProfileContext);
    const emailId = sessionStorage.getItem('emailId');


    const showAlert = () => {
        Swal.fire({
            title: "Are you sure?",
            text: `You are about to log out`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e64942",
            cancelButtonColor: "#e0bc28",
            confirmButtonText: "Yes Logout!"
        }).then((result) => {
            if (result.isConfirmed) {
                handleLogout()
            }
        });
    };

    const handleLogout = () => {
        alertErrorMessage('You are logged out..!!');
        updateProfileState({});
        sessionStorage.clear();
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <nav className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
            <button className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle" onClick={() => document.body.classList.toggle('sidenav-toggled')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
            </button>
            <span className="navbar-brand pe-3 ps-4 ps-lg-3">
                <img src="/assets/img/logo.svg" className="img-fluid" alt="logo" />
            </span>
            <ul className="navbar-nav align-items-center ms-auto">

                <li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
                    <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img className="img-fluid" src="/assets/img/dummy.png" alt="" /></a>
                    <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                        <h6 className="dropdown-header d-flex align-items-center">
                            <img className="dropdown-user-img" src="/assets/img/dummy.png" alt="" />
                            <div className="dropdown-user-details">
                                <div className="dropdown-user-details-name" style={{ textAlign: 'center' }}></div>
                                <div className="dropdown-user-details-email" style={{ paddingTop: '8px' }}>{emailId}</div>
                            </div>
                        </h6>
                        <button type="button" className="dropdown-item justify-content-center" onClick={showAlert} style={{ justifycontent: "center" }}>
                            logout
                            <div className="dropdown-item-icon ms-2"><i className="fa fa-power-off"></i></div>
                        </button>
                    </div>
                </li>
            </ul>
        </nav>
    )
}
export default Header;
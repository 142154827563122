import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";


const TradeList = () => {

  const [exportData, setExportData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const navigate = useNavigate()

  useEffect(() => {
    handleExportData();
  }, []);


  const handleExportData = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getExportList();
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        const filteredData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
        setExportData(filteredData);
        setFilterData(filteredData);
      } else {
        alertErrorMessage(result?.message);
      };
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false) };
  };

  const handleStatus = async (_id, status) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.updateTraderStatus(_id, status);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleExportData();
      } else {
        alertErrorMessage(result?.message);
      };
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false) };
  };


  const columns = [
    { name: 'S.No.', selector: (row, index) => row?.index, sort: true, wrap: true },
    { name: "User ID", selector: (row) => row._id, wrap: true },
    { name: "First Name", sort: true, wrap: true, selector: (row) => row?.firstName || '---' },
    { name: "Last Name", sort: true, wrap: true, selector: (row) => row?.lastName || '---' },
    { name: "Email", sort: true, wrap: true, selector: (row) => row?.email || '---' },
    { name: "Mobile No.", sort: true, selector: (row) => row?.phone || '---' },
    { name: "createdAt", sort: true, selector: (row) => row?.createdAt ? moment(row?.createdAt).format("Do MMMM YYYY") : '---' },

    {
      name: "Action", selector: (row) =>
        <div>
          <button className="btn btn-dark btn-sm me-2" title="View" onClick={() => { navigate('/traders_details', { state: row }) }}>
            <i className="fa-solid fa-eye"></i>
          </button>

          {row?.status === "Active" ? (
            <button className="btn btn-success btn-sm me-2"
              onClick={() => handleStatus(row?._id, "Inactive")}>
              Active
            </button>
          ) : (
            <button className="btn btn-danger btn-sm me-2"
              onClick={() => handleStatus(row?._id, "Active")}>
              Inactive
            </button>
          )}
        </div>
    },
  ];


  const resetFilterDate = () => {
    setFromDate("");
    setToDate("");
    setExportData(filterData);
  };

  const filterDate = () => {
    const filteredData = filterData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate));
    });
    setExportData(filteredData?.reverse())
  };



  const searchObjects = (e) => {
    const keysToSearch = ["firstName", "lastName", "email", "_id", "phone"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = filterData?.filter((obj) =>
      keysToSearch.some((key) => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm))
    );
    setExportData(matchingObjects);
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fas fa-users"></i>
                    </div>
                    Traders List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="filter_bar">
            <form className="row">
              <div className="mb-3 col-2">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateFrom"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="mb-3 col-2">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateTo"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="mb-3 col-3">
                <div className="row">
                  <div className="col">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                      Search
                    </button>
                  </div>
                  <div className="col">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={resetFilterDate}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              <div className="col-5">
                <input className="form-control " id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
              </div>
              <div className="dropdown">
                <button className="btn btn-dark btn-sm" type="button" >
                  <i className="fa fa-download me-3"></i>
                  <CSVLink data={exportData} className="btn btn-dark btn-sm ">Export</CSVLink>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTable
                  className="table table-hover"
                  columns={columns}
                  data={exportData}
                  noDataComponent={
                    <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TradeList;

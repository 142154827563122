import React, { useState } from 'react'
import AuthService from '../../../../services/ApiIntegration/AuthService';
import { alertErrorMessage, alertSuccessMessage } from '../../../CustomComponent/CustomAlert';
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";

const EditSubadmin = () => {

  const location = useLocation()
  const navigate = useNavigate();

  const subadminData = location.state;

  const [formData, setFormData] = useState({
    firstName: subadminData.first_name,
    lastName: subadminData.last_name,
    email: subadminData.email_or_phone,
    permissions: subadminData.permissions,
    admin_type: subadminData.admin_type,
    userId: subadminData._id
  });


  const updateSubadminList = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.updateSubadminList(formData);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        resetEditInput();
        navigate('/sub_admin_list')
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
    }
    finally { LoaderHelper.loaderStatus(false) };
  };

  const resetEditInput = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      permissions: []
    })
  };


  var options = [
    { value: 1, label: 'Traders' },
    { value: 2, label: 'KYC Manager' },
    { value: 3, label: 'Currency Management' },
    { value: 4, label: 'Currency Pair Management' },
    { value: 5, label: 'Fund Deposit Management' },
    { value: 6, label: 'Fund Withdrawal Management' },
    { value: 7, label: "Exchange Profit" },
    { value: 8, label: "Trading Report" },
    { value: 9, label: 'OrderBook' },
    { value: 10, label: 'Support' },
    { value: 11, label: 'Notification Management' },
    { value: 12, label: 'Banner Management' },
  ];


  const editSubadminDetails = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, permissions: e || [] });
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <Link to="/sub_admin_list" className="page-header-icon">
                      <i className="fa fa-arrow-left"></i>
                    </Link>
                    <div className="page-header-icon"><i className="fas fa-user-shield"></i></div>
                    Edit Subadmin Details
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">Enter Sub Admin Details</div>
            <div className="card-body">
              <form>
                <div className="row gx-3 mb-3 d-flex justify-content-evenly">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputFirstName">First name <em>*</em></label>
                    <input className="form-control  form-control-solid" id="inputFirstName" type="text" name='firstName' placeholder="Enter your first name" value={formData.firstName} onChange={editSubadminDetails} />
                  </div>
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputLastNames">Last name <em>*</em> </label>
                    <input className="form-control form-control-solid" id="inputLastNames" type="text" name='lastName' placeholder="Enter your last name" value={formData.lastName} onChange={editSubadminDetails} />
                  </div>

                </div>
                <div className="row gx-3 mb-3 d-flex justify-content-evenly">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputEmailAddress">Email</label>
                    <input className="form-control form-control-solid" id="inputEmailAddress" name='email' type="email" placeholder="Enter your email address" value={formData.email} onChange={editSubadminDetails} />
                  </div>
                  <div className="col-md-4" >
                    <label className="small mb-1" htmlFor="inputLocation">Permissions</label>
                    <MultiSelect
                      options={options}
                      value={formData.permissions}
                      onChange={editSubadminDetails}
                      labelledBy="Select"
                    />
                  </div>
                </div>

                <div className='d-flex justify-content-center'>
                  <div className="row gx-3 mt-4 mb-3">
                    <button className="btn btn-indigo" type="button" onClick={updateSubadminList}> Submit Details </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default EditSubadmin
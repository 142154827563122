import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from '../../CustomComponent/LoaderHelper/LoaderHelper'
import { alertErrorMessage, alertSuccessMessage } from "../../CustomComponent/CustomAlert";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { ProfileContext } from "../../CustomComponent/ProfileContext";


const LoginPage = () => {
    const navigate = useNavigate();

    const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [, updateProfileState] = useContext(ProfileContext);

    useEffect(() => {
        const enterToLogin = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleLogin(loginInfo.email, loginInfo.password);
            };
        };
        document.addEventListener('keydown', enterToLogin);
        return () => {
            document.removeEventListener('keydown', enterToLogin);
        };
    });

    const handleLogin = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.login(loginInfo);
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                sessionStorage.setItem("token", result.data.token);
                sessionStorage.setItem("emailId", result.data.email_or_phone);
                sessionStorage.setItem("userType", result.data.admin_type);
                sessionStorage.setItem("userId", result.data.id);
                sessionStorage.setItem("permissions", JSON.stringify(result?.data?.permissions || []));
                alertSuccessMessage('Login Successfull!!');
                updateProfileState(result?.data);
                navigate('/dashboard');
            } else {
                alertErrorMessage(result);
            };
        } catch (error) {
            alertErrorMessage(error?.message);
        }
        finally { LoaderHelper.loaderStatus(false) };
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginInfo({ ...loginInfo, [name]: value });
    };

    return (
        <div className="login-card" >
            <div className="container-xl px-4">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                        <div className="card my-5">
                            <div className="card-body p-r text-center">
                                <img src="/assets/img/logo.svg" className="img-fluid" alt="" width='200' />
                            </div>
                            <div className="card-body p-3 p-md-5 pt-md-0">
                                <div className="h2 text-center mb-2"> <strong>Sign in to account</strong></div>
                                <div className="text-center small text-muted mb-4"> Enter your email & password to login </div>
                                <form>
                                    <div className="mb-3">
                                        <label className="text-gray-600 small" htmlFor="emailExample">Email Address</label>
                                        <input className="form-control form-control-solid" type="email" name="email" placeholder="Enter email address" aria-label="Email Address" aria-describedby="emailExample" value={loginInfo.email} onChange={handleInputChange} />
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label className="text-gray-600 small" htmlFor="passwordExample">Password</label>
                                        <div className="input-group">
                                            <input type={showPassword ? 'text' : "password"} name="password" id="password" className="form-control form-control-solid" placeholder="Password" required value={loginInfo.password} onChange={handleInputChange} />
                                            <button type="button" className="btn btn-dark btn-icon btn_view_pass " onClick={() => setShowPassword(!showPassword)}>
                                                <i className={`fa-solid fa-eye${showPassword ? "-slash" : ""}`} />
                                            </button>
                                        </div>
                                    </div>

                                    <div>
                                        <Link className="btn-link text-decoration-none text-black" to="/forgotpassword">Forgot your password..?</Link>
                                    </div>
                                    <div className="text-center py-3 mt-2">
                                        <button type="button" className="btn btn-block w-100 btn-xl btn btn-indigo btn_admin "
                                            onClick={handleLogin}>
                                            SIGNIN
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;